import React, { Component } from 'react';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import MessagesApi from '../api/messages.api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalCount: 10,
      searchText: '',
    };

    this.getMessages = this.getMessages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  async getMessages(pageNumber) {
    try {
      const result = await MessagesApi.getAllMessages(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText
      );
      this.setState({ messages: result.messages });
    } catch (error) {
      console.log(error);
      toast.error('Грешка при превземањето на пораките');
    }
  }
  async componentDidMount() {
    await this.getMessages();
  }

  async handlePageClick(e) {
    await this.getMessages(e.selected + 1);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    return (
      <Row className='table-section'>
        <ToastContainer />
        <Col lg={12}>
          <figure className='icon-group'>
            <h2 className='heading ml-3'>Коментари / Прашања</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type='number'
              name='searchText'
              placeholder='Пребарај тел.број'
              className='mr-sm-2'
              onChange={this.handleChange}
            />
            <Button
              variant='btn mr-2 button-standard'
              onClick={() => this.getMessages()}
            >
              <i className='material-icons'>search</i>
              Пребарај
            </Button>
            <Button
              className='btn mr-2 button-standard'
              onClick={() => this.getMessages()}
            >
              <i className='material-icons'>refresh</i> Освежи
            </Button>
          </Form>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead className='table-head'>
                <tr>
                  <th>Име на испраќач</th>
                  <th>Тел. Број на испраќач</th>
                  <th>Коментар/Прашање</th>
                  <th>Дата</th>
                  <th>Акција</th>
                </tr>
              </thead>
              <tbody>
                {this.state.messages
                  ? this.state.messages.rows.map((i) => {
                      if (i.user) {
                        return (
                          <tr key={i._id}>
                            <td>{i.user.full_name}</td>
                            <td>{i.user.user_phone}</td>
                            <td>
                              {i.answers == null ? (
                                <i
                                  className='material-icons pointer error'
                                  title='Нема одговор за овај коментар/прашање'
                                >
                                  error
                                </i>
                              ) : i.isTheLastUpdateFromUser ? (
                                <i
                                  className='material-icons pointer error'
                                  title='Корисникот внесе нов коментар/прашање'
                                >
                                  error
                                </i>
                              ) : null}

                              {i.isPicture ? (
                                <img
                                  className='pointer thumbnail'
                                  src={i.url}
                                  onClick={() => window.open(i.url, '_blank')}
                                ></img>
                              ) : (
                                i.question_or_comment
                              )}
                            </td>
                            <td>{i.date}</td>
                            <td>
                              <Link
                                className='btn button-standard'
                                to={`/messages/${i._id}`}
                              >
                                <i className='material-icons'>search</i>
                                Прегледај одговори
                              </Link>
                            </td>
                          </tr>
                        );
                      }
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Претходна'}
              nextLabel={'Следна >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.messages && this.state.messages.count
                  ? Math.ceil(this.state.messages.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link z-index-0'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
              className='z-index-0'
            />
          </section>
        </Col>
      </Row>
    );
  }
}
