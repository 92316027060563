import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import LoginPage from './components/Login';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import UsersList from './components/UsersList';
import Messages from './components/Messages';
import Admins from './components/Admins';
import Logo from './images/logo.png';
import Cookies from 'js-cookie';
import { getUserFromJWT } from './utils/jwt.decode';
import Download from './components/Download';
import Answers from './components/Answers';
import { ToastContainer } from 'react-toastify';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isLoggedIn: false,
    };
    this.reRender = this.reRender.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  componentDidMount() {
    const user = getUserFromJWT();
    if (user) {
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
        },
        isLoggedIn: true,
      });
      Cookies.set('currentUser', user.userName);
      Cookies.set('isSuperAdmin', user.isSuperAdmin);
    }
  }

  handleLogOut() {
    Cookies.remove('jwt');
    Cookies.remove('currentUser');
    Cookies.remove('isSuperAdmin');
    this.setState({ currentUser: null, isLoggedIn: false });
    window.location.replace('/');
  }

  reRender() {
    this.forceUpdate();
    const user = getUserFromJWT();
    if (user)
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
        },
        isLoggedIn: true,
      });
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Router>
          <ToastContainer />
          <Navbar
            sticky='top'
            expand='lg'
            variant='light'
            className='main-navigation'
          >
            <Navbar.Brand>
              <Link to='/' className='navbar-brand'>
                <img src={Logo} alt='neotel-logo' className='height-70' />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='mr-auto'>
                <Nav>
                  <Link to='/users' className='nav-link-item'>
                    <i className='material-icons'>people</i>Клиенти
                  </Link>
                </Nav>
                <Nav>
                  <Link to='/messages' className='nav-link-item'>
                    <i className='material-icons'>question_answer</i>
                    Коментари/Прашања
                  </Link>
                </Nav>
                {this.state.currentUser &&
                this.state.currentUser.isSuperAdmin ? (
                  <Nav>
                    <Link to='/admins' className='nav-link-item'>
                      <i className='material-icons'>account_box</i>
                      Администратори
                    </Link>
                  </Nav>
                ) : null}
              </Nav>
              <Nav>
                <i className='material-icons md-24 logged-user'>
                  account_circle
                </i>
                <NavDropdown
                  title={Cookies.get('currentUser')}
                  id='basic-nav-dropdown'
                  className='nav-link-item'
                >
                  <NavDropdown.Item
                    onClick={this.handleLogOut}
                    className='nav-ling-item'
                  >
                    Одјави се
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route exact path='/'>
              <UsersList />
            </Route>
            <Route path='/users'>
              <UsersList />
            </Route>
            <Route path='/messages' exact>
              <Messages />
            </Route>
            <Route path='/messages/:id' component={Answers} />
            <Route path='/admins'>
              <Admins />
            </Route>
            <Route path='/export'>
              <Download></Download>
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <LoginPage afterLogin={this.reRender} />;
    }
  }
}
export default App;
