import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import messagesApi from '../api/messages.api';
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap';
import MessagesApi from '../api/messages.api';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

export default class Answers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: null,
      currentMessage: '',
      currentMessageId: '',
      file: null,
      fileError: '',
      message: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendAnswer = this.sendAnswer.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  async getMessage() {
    try {
      const result = await messagesApi.getMessage(this.props.match.params.id);
      this.setState({
        message: result.message,
        currentMessageId: this.props.match.params.id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.getMessage();
  }

  onChangeHandler = (event) => {
    const target = event.target.files[0].name.slice(
      ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2
    );
    if (target.toLowerCase() == 'png' || target.toLowerCase() == 'jpg') {
      this.setState({
        fileError: '',
        file: event.target.files[0],
      });
    } else {
      this.setState({
        fileError:
          'Неправилен формат на фајл. Форматот мора да биде .jpg или .png',
      });
    }
  };

  async sendAnswer() {
    try {
      if (this.state.fileError) {
        return;
      }
      if (this.state.file == null && this.state.currentMessage == '') {
        toast.warn('Ве молиме внесете текст или одберете слика');
        return;
      }
      let result = 200;
      if (this.state.file == null) {
        result = await MessagesApi.sendAnswer(
          this.state.currentMessageId,
          this.state.currentMessage,
          Cookies.get('currentUser')
        );
      } else {
        result = await MessagesApi.sendAnswerWithImage(
          this.state.currentMessageId,
          this.state.file,
          this.state.currentMessage,
          Cookies.get('currentUser')
        );
      }

      if (result.status === 200) {
        toast.success('Одговорот беше успешно испратен');
        this.getMessage();
        this.setState({
          file: null,
          fileError: '',
          currentMessage: '',
        });
        document.getElementById('files-upload').value = null;
      } else {
        toast.error('Грешка при испраќање');
      }
    } catch (error) {
      console.log(error);
      toast.error('Грешка при испраќање');
    }
  }

  render() {
    return (
      <Row className='table-section'>
        <Col>
          <h4 className='heading'>
            Разговор со :{' '}
            {this.state.message != null
              ? this.state.message.user.full_name
              : null}
          </h4>
          <h4 className='heading'>
            Тел-број :{' '}
            {this.state.message != null
              ? this.state.message.user.user_phone
              : null}
          </h4>
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm='6' className='heading'>
                Внесете нов одговор
              </Form.Label>
              <Col sm='10'>
                <Form.Control
                  as='textarea'
                  rows='3'
                  name='currentMessage'
                  onChange={this.handleChange}
                  className='text-area'
                  value={this.state.currentMessage}
                />
                <input
                  type='file'
                  name='file'
                  onChange={this.onChangeHandler}
                  className='margin-top-10px'
                  id='files-upload'
                />
                {this.state.fileError != '' ? (
                  <span className='error'>{this.state.fileError}</span>
                ) : null}
                <Button
                  variant='outline-warning mb-3 mt-3'
                  onClick={this.sendAnswer}
                  sm='3'
                  size='sm'
                  className='margin-top-10px button-standard'
                >
                  Испрати
                </Button>
              </Col>
            </Form.Group>
            <Form.Group as={Row}></Form.Group>
          </Form>
        </Col>

        <Col lg={12}>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead className='table-head'>
                <tr>
                  <th>Корисник</th>
                  <th>Коментар</th>
                  <th>Слика</th>
                  <th>Дата</th>
                </tr>
              </thead>
              <tbody>
                {this.state.message != null &&
                this.state.message.answers != null
                  ? this.state.message.answers.map((i, v) => {
                      return (
                        <tr key={v}>
                          <td>{i.answeredBy}</td>
                          <td>{i.answer}</td>
                          <td>
                            {i.isPicture ? (
                              <img
                                className='pointer thumbnail'
                                src={i.url}
                                onClick={() => window.open(i.url, '_blank')}
                              ></img>
                            ) : null}
                          </td>
                          <td>{i.answeredOn}</td>
                        </tr>
                      );
                    })
                  : null}
                <tr>
                  <td>
                    {this.state.message && this.state.message.user
                      ? this.state.message.user.full_name
                      : null}
                  </td>
                  <td>
                    {this.state.message &&
                    this.state.message.question_or_comment
                      ? this.state.message.question_or_comment
                      : null}
                  </td>
                  <td>
                    {this.state.message && this.state.message.isPicture ? (
                      <img
                        className='pointer thumbnail'
                        src={this.state.message.url}
                        onClick={() =>
                          window.open(this.state.message.url, '_blank')
                        }
                      ></img>
                    ) : null}
                  </td>
                  <td>
                    {this.state.message && this.state.message.user
                      ? this.state.message.date
                      : null}
                  </td>
                </tr>
              </tbody>
            </Table>
          </section>
        </Col>
      </Row>
    );
  }
}
